<template>
  <!-- product card -->
  <div class="pcart" v-if="item && item.category">
    <NuxtLink :to="`/${item.category.slug}/${item.slug}`">
      {{ favoriteStatus }}

      <div class="image" v-if="item.images">
        <img
          v-if="item.images && item.images.length > 0"
          :src="'https://uygunsecim.b-cdn.net/' + item.images[0].url"
          alt=""
        />
        <div class="action">
          <a
            href="#"
            @click.prevent="toggleFavorite(item.uniqueId, favoriteStatus)"
          >
            <i class="icon lnr lnr-heart" :class="{ down: favoriteStatus }"></i>
          </a>
        </div>
        <div
          class="badges"
          v-if="
            reportid !== undefined &&
            reportid >= 0 &&
            item['report_' + reportid]
          "
        >
          <span
            v-bind:class="{
              up: item['report_' + reportid].percentage_difference < 0,
              down: item['report_' + reportid].percentage_difference > 0,
            }"
          >
            <UIcon
              class="icon"
              v-if="item['report_' + reportid].percentage_difference > 0"
              name="i-heroicons-arrow-trending-up"
              dynamic
            />
            <UIcon
              class="icon"
              v-else
              name="i-heroicons-arrow-trending-down"
              dynamic
            />
            {{ Math.abs(item["report_" + reportid].percentage_difference) }}%
            {{
              item["report_" + reportid].percentage_difference > 0
                ? "arttı"
                : "düştü"
            }}
          </span>
        </div>
      </div>

      <div class="content">
        <div class="rate-box">
          <div
            class="rate-cover"
            v-if="item.review_score && item.review_score > 0"
          >
            <div
              class="rating"
              :style="{
                width: `${(item.review_score / 5) * 100}%`,
              }"
            ></div>
          </div>

          <span class="text" v-if="item.review_score">
            {{ item.review_score > 0 ? item.review_score : "" }}</span
          >
        </div>

        <h2>{{ item.name }}</h2>
        <div class="prices">
          <div class="price" v-for="(offer, index) of item.offers" :key="index">
            <span class="now">{{ offer.price }} TL</span>
            <span class="target">{{ offer.marketplace.name }}</span>
          </div>
        </div>
      </div>
      <span class="next">+{{ item.data_count }} fiyatı incele</span>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
// const { baseURL } = useRuntimeConfig();

// props tanımlaması
const props = defineProps<{
  item: any;
  reportid: any;
}>();

const router = useRouter();

const favoriteStatus = ref(false); //ref(props.item.isFavorite);

// in the default layout, but I guess it should be somewhere else
onMounted(() => {
  favoriteStatus.value = props.item.isFavorite;
});

// Favori durumunu toggle etme (ekleme veya kaldırma)
const toggleFavorite = async (uniqueId: string, isFavorite: boolean) => {
  try {
    const token = localStorage.getItem("token");

    let apiUrl = "";
    if (isFavorite) {
      apiUrl = `https://api.fiyatavantaji.com/api/remove-favorites?uniqueId=${uniqueId}`;
    } else {
      apiUrl = `https://api.fiyatavantaji.com/api/add-favorites?uniqueId=${uniqueId}`;
    }

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      console.log("Ürün favorilere eklendi veya kaldırıldı");

      favoriteStatus.value = !favoriteStatus.value;

      // cartKey değerini artır
    } else {
      console.error("Ürün favorilere eklenemedi veya kaldırılamadı");
    }
  } catch (error) {
    console.error(
      "Favori ürün eklenirken veya kaldırılırken bir hata oluştu",
      error
    );
  }
};
</script>

<style scoped>
.down {
  color: red;
}

.pcart .image {
  max-height: 150px;
}
</style>
